import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import {
  Link,
  Button
} from "gatsby-theme-material-ui";
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ListItemText from "@material-ui/core/ListItemText"
import {FaFirefox, FaChrome, FaEdge, FaSafari} from 'react-icons/fa'
const IndexPage = () => (
  <Container maxWidth="lg">
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        Goto - URL shortener
      </Typography>

      <Box my={2}>
        <Button
          color="secondary"
          to={'https://go.goto.ycoll.com'}
        >To URL Shortener web page
        </Button>
      </Box>

      <Box my={4}>
        <Typography variant="p" component="p" gutterBottom>
          Goto is a company internal url shortener where the users can decide which keyword points to what web site. It allows you to use <em>go/intranet</em> to go to your intranet landing site, or <em>go/projectX</em> to go to the internal information page for your exciting new project.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="p" component="p" >
          It allows you to quickly navigate to often used web pages without remembering long urls. And for everyone to easily share pages verbally and in presentations, "and my slide deck is available at go/allhands-2020-06".
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="p" component="p">
          Browser extensions for make use of <em>go/</em> shortcut can be found here:
        </Typography>

        <List dense={false}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FaChrome />
              </Avatar>
            </ListItemAvatar>
            <Link to={'https://chrome.google.com/webstore/detail/urlshort/ldlffpdodlfnhpegfcgcginljnlpnegb?hl=en'}>
              <ListItemText
                primary="Chrome"
              />
            </Link>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FaFirefox />
              </Avatar>
            </ListItemAvatar>
            <Link to={'https://addons.mozilla.org/en-US/firefox/addon/urlshortgoto/'}>
              <ListItemText
                primary="Firefox"
              />
            </Link>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FaEdge />
              </Avatar>
            </ListItemAvatar>
            <Link to={'https://microsoftedge.microsoft.com/addons/detail/dejfapabeogefdajoinnmcgmalfpcake'}>
              <ListItemText primary="Edge" />
            </Link>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FaSafari />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Safari"
              secondary="Not started - let us know if you need this"
            />
          </ListItem>
        </List>
      </Box>

      <Box my={4}>
      <Typography variant="p" component="p" gutterBottom>
        Contact <Link to="mailto:eirik@ycoll.com">Eirik Nygaard</Link> for more details, pricing, and to set up an account for your organisation.
      </Typography>
      </Box>
      <Box my={4}>
      <Typography variant="p" component="p" gutterBottom>
        <Link to="/privacypolicy">
          Privacy Policy
        </Link>
      </Typography>
      </Box>
    </Box>
  </Container>

  /*<Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
  </Layout>*/
)

export default IndexPage
